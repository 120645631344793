iframe {
    border: 0px;
    margin-top: 0px;
}

.noReport {
    text-align: center;
    margin-top: 20%;
}

.reportClass,
.reportClass iframe {
    width: 100%;
    display: block;
    height: 96vh;
    margin-top: -13px;

    /* width: 107%;
    display: block;
    height: 96vh;
    margin-left: -55px !important;
     */
}