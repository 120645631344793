body {
  overflow: hidden;
}

.sidebar {
  min-width: 300px;
  max-width: 300px;
  background: #ffffff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  /* box-shadow: rgb(0 0 0 / 10%) 0px 10px 50px; */
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.sidebar li a.active,
.sidebar ul li:hover {
  background: #F4F4F4;
  /* box-shadow: inset 4px 0px 0px #ED1B46; */
}

.sidebar ul li a.active>a,
.sidebar ul li:hover a,
.sidebar ul li:hover a span,
.sidebar ul.dropdown-menu li a {
  color: #0B0B0C;
}


.sidebar ul li a.active>a,
.sidebar ul.dropdown-menu li a {
  color: #0B0B0C;
  padding-left: 45px;
}

li a.active span {
  color: #FF3E5B !important;
}

.sidebar.active {
  margin-left: -100px;
}


.sidebar ul.components {
  padding: 0;
}

.sidebar ul li {
  font-size: 16px;
}

.sidebar ul li>ul li {
  font-size: 14px;
  padding: 0 0;
}

.sidebar ul li a {
  position: relative;
  padding: 10px 15px;
  display: block;
  color: #7b7b7b;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 500;
}

/* .sidebar ul li a::before {
  content: "";
  position: absolute;
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  right: 15px;
  top: 20px;
} */

.dropdown-menu.show {
  transform: none !important;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  width: 100%;
  position: unset !important;
  padding: 0;
  border: none;
}

.dropdown-menu.show li a.active,
.dropdown-menu.show li:hover {
  background: #F2F2F2;
}

.dropdown-menu.show li a.active a,
.dropdown-menu.show li:hover a {
  color: #FFFFFF;
}

.dropdown.show a::before {
  transform: rotate(45deg);
}

.dropdown-toggle::after,
.dropdown-menu.show a::before {
  display: none;
}

a:hover {
  text-decoration: none;
}

.content {
  width: 100%;
  padding: 0 !important;
  background: #E5E5E5;
  /* height: calc(100vh - 70px); */
  height: calc(100vh - 0px);
  /* overflow: auto; */
  /* overflow: scroll; */
}

.sidebar .components {
  height: calc(100vh - 70px);
  overflow: auto;

  background-image: url('../../../public/sidebarBackground.svg');
  background-repeat: no-repeat;
  background-position: bottom;

}

.dropdown-menu li a.active span,
.dropdown-menu li a.active span:hover,
.dropdown-menu li:hover a span {
  color: #0B0B0C !important;
}


/* .sidebar li a.active, .sidebar ul li:hover {
  background: #F4F4F4;
  box-shadow: inset 4px 0px 0px #ED1B46;
} */

.logoSidebar {
  padding: 20px 30px;
}