.topbar {
  padding: 0px 0px;
  /* border-bottom: 1px solid #DEDEDE; */
  background: white;
  height: 57px;
}

.logo {
  min-width: 192px;
  max-width: 192px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #C1C1C1;
}

.user {
  padding-left: 2rem;
}

.user h2 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #080707;
}

.image-div {
  background-image: url("../../userProfile.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-size: cover;
}

.name-div {
  width: max-content;
  white-space: nowrap;
  /* display: flex; */
  align-items: center;
  margin-left: 8px;
}

.userdetail {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 35px;
}

.logoutImage {
  width: 30px;
  cursor: pointer;
  margin-left: 10px;
}