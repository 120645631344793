.parent-div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("loginbg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.login-button {
    border: none;
    outline: none;
    background: #4f52b2;
    border-radius: 5.03061px;
    color: #fff;
    padding: 10px;
    font-size: 14px;
}

.welcome-arrow h3 {
    color: #2f2f2f;
    font-weight: 600;
    font-size: 2rem;
}

.bottom-img1 {
    /* background-image: url("../../../../../assets/left-cloud.png"); */
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 19vh;
    background-size: contain;
}

.bottom-img2 {
    /* background-image: url("../../../../../assets/right-cloud.png"); */
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 19vh;
    background-size: contain;
}

.manage-text {
    color: #9198a0;
    padding: 0rem 5rem;
}

.welcome-div {
    background: #FFFFFF;
    border: 1px solid rgba(219, 213, 253, 0.4);
    border-radius: 8px;
    width: 40%;
    padding-bottom: 23px;
    box-shadow: 0px 6px 20px 0px #25242414;
    transition: all 0.5s ease;
}

.bottom-cloud {
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 10vh;
    /* background-image: url("../../../../../assets/bottom-combined-cloud.png"); */
    background-repeat: no-repeat;
    background-size: cover;
}
.login-button {
    border: none;
    outline: none;
    background: #4f52b2;
    border-radius: 5.03061px;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    padding-right: 30px;
}
.ms-3 {
    margin-left: 1rem!important;
}
.me-4 {
    margin-right: 1.5rem!important;
}
.logClassHome{
    display: flex;
    justify-content: center;
}